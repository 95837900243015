<template>
    <div>
        <div class="flex items-center justify-between mb-6">
            <h1 class="text-white text-2xl font-medium">Signals</h1>
        </div>

        <table class="table-fixed text-white w-full">
            <thead class="bg-woodsmoke border-t border-border-color">
                <th class="w-1/6 text-left px-4 py-4">Asset</th>
                <th class="w-1/6 text-left px-4 py-4">Country</th>
                <th class="w-2/6 text-left px-4 py-4">Description</th>
                <th class="w-1/6 text-left px-4 py-4">Direction</th>
                <th class="w-1/6 text-left px-4 py-4">Time</th>
                <th class="w-1/6 text-left px-4 py-4">Max age</th>
            </thead>
            <tbody is="transition-group"
                enter-to-class="bg-azure-radiance"
                leave-to-class="bg-azure-radiance"
                duration="2500">
                <tr class="border-t border-border-color transition-all duration-500" :class="{'bg-woodsmoke': index % 2}" v-for="(value, index) in signals" :key="value.timestamp">
                    <td class="px-4 py-4">
                        <a v-bind:href="'#/assets/' + value.asset" class="border-b border-azure-radiance">{{ value.asset }}</a>
                    </td>
                    <td class="px-4 py-4">
                        {{ value.country }}
                    </td>
                    <td class="px-4 py-4">
                        {{ value.description }}
                    </td>
                    <td class="px-4 py-4">
                        {{ value.direction }}
                    </td>
                    <td class="px-4 py-4">
                        {{ value.timestamp | toTime }}
                    </td>
                    <td class="px-4 py-4">
                        {{ value.max_age }}
                    </td>
                </tr>
            
            </tbody>
        </table>
        
    </div>
</template>

<script>
    import axios from "axios"
    import _ from "lodash"

    export default {
        name: 'Signals',
        data: function() {
            return {
                signals: [],
                interval: ''
            }
        },
        filters: {
            toTime: function(value) {
                const date = new Date(value * 1000)
                const year = date.getFullYear()
                const month = ('0' + (date.getMonth()+1)).slice(-2)
                const day = ('0' + date.getDate()).slice(-2)
                const hour = ('0' + date.getHours()).slice(-2)
                const minutes = ('0' + date.getMinutes()).slice(-2)

                return year + "/" + month + "/" + day + " " + hour + ":" + minutes
            }
        },
        mounted() {
            const current = this

            // 3 days of signals
            current.getSignals(259200, true)

            this.interval = setInterval(function() {
                
                // 5 minutes of signals
                current.getSignals(300, false)

            }, 5000)

        },
        methods: {
            getSignals: function(duration, initial) {
                const timestamp = Date.now() / 1000
                const calculatedTimestamp = Math.round(timestamp - duration)

                axios.get("https://ex.evr.ax:8001/signals?start="+ calculatedTimestamp)
                    .then(response => {
                        
                        for ( var index in response.data ) {
                            if (initial) {
                                this.signals.push(response.data[index])
                            } else {
                                const foundRows = _.find(this.signals, { 'timestamp': response.data[index].timestamp });

                                // check if new rows are unique
                                if (!foundRows) {
                                    this.signals.unshift(response.data[index])
                                }
                            } 
                        }            
                    })
                    .catch(err => {
                        
                    })
            }
        },
        beforeRouteLeave (to, from , next) {
            clearTimeout(this.interval)
            next()
        }
    }
</script>