<template>
    <div>
        <input type="radio" :value="value" :id="value" v-model="proxyChecked" class="hidden" />
        <label :for="value" class="flex m-1 py-2 px-4 rounded bg-charade text-white cursor-pointer" :class="{'bg-azure-radiance': isSelected(value)}"><img v-if="image" :src="getImgUrl(text)" :alt="text" class="w-5 mr-4">{{ text | capitalize }}</label>
    </div>
</template>

<script>
    export default {
        name: 'radiobutton',
        model: {
            prop: 'checked',
            event: 'change'
        },
        props: {
            checked: {
                type: [String, Number, Boolean, Object, Array],
                default: false
            },
            value: {
                default: null
            },
            text: {
                default: null
            },
            image: [Boolean]
        },
        computed: {
            proxyChecked: {
                get() {
                    return this.checked
                },
                set(val) {
                    this.$emit('change', val)
                }
            }
        },
        methods: {
            getImgUrl: function(img) {
                return require('@/assets/img/'+ img.toLowerCase().replace(/\s/g, '-') + '.svg')
            },
            isSelected: function(value) {
                return this.proxyChecked.includes(value)
            }
        }
    }
</script>