<template>
	<div id="app" class="">
		<header class="flex items-center flex-col md:flex-row px-7 lg:px-16 py-7">
			<router-link to="/" class="flex self-start md:self-center flex-none items-center">
    			<img src="./assets/img/evr-logo.svg" alt="EVR Logo" width="84" height="28">
			</router-link>
			<autocomplete></autocomplete>

			<router-link to="/myassets" class="absolute md:relative flex-none right-7 md:right-0 ml-8 xl:ml-auto text-white flex items-center focus-visible:outline-white">
                <svg class="text-azure-radiance mr-2 flex-none"  width="18" height="18" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chart-pie-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M461.29 288H224V50.71c0-8.83-7.18-16.21-15.74-16.21-.69 0-1.4.05-2.11.15C87.08 51.47-3.96 155.43.13 280.07 4.2 404.1 107.91 507.8 231.93 511.87c2.69.09 5.39.13 8.07.13 121.04 0 220.89-89.66 237.35-206.16 1.33-9.45-6.52-17.84-16.06-17.84zM240 480c-2.33 0-4.68-.04-7.02-.12-107.24-3.52-197.35-93.63-200.87-200.87C28.84 179.02 96.45 92.23 192 69.83V320h250.15C420.27 412.43 336.49 480 240 480zM288.8.04c-.35-.03-.7-.04-1.04-.04C279.1 0 272 7.44 272 16.23V240h223.77c9.14 0 16.82-7.69 16.2-16.8C503.72 103.74 408.26 8.28 288.8.04zM304 208V33.9c89.25 13.81 160.28 84.85 174.1 174.1H304z"></path>
                </svg>

				<span class="flex-none">My assets</span>
			</router-link>
		</header>
        <nav id="nav" class="bg-charade flex items-center px-7 lg:px-16">
          <ul class="flex space-x-6 items-center">
              <li class="py-6"><router-link to="/" class="text-white block border-b-2 border-transparent focus-visible:outline-white" exact-active-class="border-azure-radiance font-bold">Home</router-link></li>
              <li class="py-6"><router-link to="/momentum_tables" class="text-white block border-b-2 border-transparent focus-visible:outline-white" active-class="border-azure-radiance font-bold">Momentum Tables</router-link></li>
              <li class="py-6"><router-link to="/signals" class="text-white block border-b-2 border-transparent focus-visible:outline-white" active-class="border-azure-radiance font-bold">Signals</router-link></li>
              <li class="py-6"><router-link to="/news" class="text-white block border-b-2 border-transparent focus-visible:outline-white" active-class="border-azure-radiance font-bold">News</router-link></li>
              <li class="py-6"><router-link to="/assets" class="text-white block border-b-2 border-transparent focus-visible:outline-white" active-class="border-azure-radiance font-bold">Assets</router-link></li>
          </ul>
          <router-link to="/compare" class="flex border border-border-color rounded py-2 px-4 ml-auto text-white items-center focus-visible:outline-white">Compare stocks <span class="flex items-center justify-center text-white bg-azure-radiance rounded h-8 w-8 ml-4">2</span></router-link>
        </nav>

        <error-message></error-message>

		<router-view :key="$route.fullPath" class="relative container mx-auto py-10" />
	</div>
</template>

<script>
	import autocomplete from '@/components/autocomplete.vue'
    import errorMessage from '@/components/error-message.vue'

	export default {
		components: {
			autocomplete,
            errorMessage
		},
		data: function() {
			return {

			}
		}
	}
</script>
