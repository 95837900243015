<template>
	<div class="home">

		<div class="flex justify-start flex-wrap mb-4">
			<div v-for="country in countries">
				<radiobutton v-for="(value, key, index) in country" :index="index" :key="key" :value="key" :text="value.country" :image="true" v-model="checkedCountries"></radiobutton>
			</div>
		</div>

		<div class="flex justify-start flex-wrap hidden">
			<radiobutton v-for="(value, index) in sectors" :index="index" :key="key" :value="value" :text="value" :image="false" v-model="checkedSectors"></radiobutton>
		</div>

		<div class="flex justify-between">
            <div class="flex flex-col">
                <h1 class="mb-2 text-white text-2xl font-medium">{{ title }}</h1>
        		<div class="flex items-center space-x-2 mb-4 text-white text-sm">
                    <span class="uppercase">{{ checkedCountries }}</span>
                    <span class="h-1.5 w-1.5 bg-azure-radiance block rounded-sm"></span>
                    <span class="uppercase">{{ checkedSectors }}</span>
                </div>
            </div>
            <div>
                <select class="h-auto py-2 px-2 pr-10 border border-border-color bg-transparent rounded focus:outline-none focus:ring-azure-radiance focus:border-azure-radiance text-white" v-model="page">
                    <option v-for="(page, index) in pages" :value="index">{{ page.text }}</option>
                </select>
            </div>
        </div>


		<lineChart v-bind:lineData="absoluteData" class="mb-8"></lineChart>

        <h1 class="mb-2 text-white text-2xl font-medium">Relative momentum 200-days</h1>
        <div class="flex items-center space-x-2 mb-4 text-white text-sm">
            <span class="uppercase">{{ checkedCountries }}</span>
            <span class="h-1.5 w-1.5 bg-azure-radiance block rounded-sm"></span>
            <span class="uppercase">{{ checkedSectors }}</span>
        </div>
        <lineChart v-bind:lineData="relativeData"></lineChart>

	</div>
</template>

<script>
	import axios from "axios";
	import lineChart from '@/components/linechart.vue'
	import radiobutton from '@/components/radiobutton.vue'

	export default {
		name: 'Home',
		components: {
			lineChart,
			radiobutton
		},
		data: function() {
			return {
				title: 'Absolute momentum 200-days',
				assets: '',
				countries: [],
				sectors: [],
				checkedCountries: '',
				checkedSectors: '',
				absoluteData: {},
                relativeData: {},
                pages: [{start: 0, end: 0, text: '0 - 10'}],
                page: 0
			}
		},
		mounted() {

			// Get all countries
			axios.get("https://ex.evr.ax:8001/assets/countries")
                .then(response => {
                    this.countries = response.data;
                })
                .catch(err => {
                    console.log(err);

                    this.$root.$emit('showErrorMessage', 'Error', err)
                })

            // Get all sectors    
            axios.get("https://ex.evr.ax:8001/assets/sectors")
                .then(response => {
                    this.sectors = response.data;
                })
                .catch(err => {
                    console.log(err);

                    this.$root.$emit('showErrorMessage', 'Error', err)
                })

            this.checkedCountries = 'AS'
		},
		watch: {
			checkedCountries: function() {
				axios.get("https://ex.evr.ax:8001/trends/momentum?selector=countries&selected="+ this.checkedCountries +"&start=0&end=10")
                .then(response => {
                    this.absoluteData = response.data['absolute']
                    this.relativeData = response.data['relative']

                    this.generatePages(response.data.total_length)

                })
                .catch(err => {
                    console.log(err);

                    this.$root.$emit('showErrorMessage', 'Error', err)
                })
			},
            page: function() {
                const start = this.page * 10
                const end = (this.page + 1) * 10

                axios.get("https://ex.evr.ax:8001/trends/momentum?selector=countries&selected="+ this.checkedCountries +"&start="+ start +"&end="+ end)
                    .then(response => {
                        this.absoluteData = response.data['absolute']
                        this.relativeData = response.data['relative']
                    })
                    .catch(err => {
                        console.log(err);

                        this.$root.$emit('showErrorMessage', 'Error', err)
                    })
            }
		},
		methods: {
			generatePages: function(value) {
                const nrPages = Math.floor(value / 10)
                const pagesArray = []
                
                for (var i = nrPages - 1; i >= 0; i--) {
                    pagesArray.push({start: i * 10, end: (i+1)*10, text: i * 10 + " - " + (i+1)*10})
                }

                this.pages = pagesArray.reverse()
            }
		}
	}
</script>
