<template>
    <div class="chart">
        <div ref="graph" class="w-full" style="height: 420px;"></div>
    </div>
</template>

<script>
    import { createChart } from 'lightweight-charts'

    export default {
        name: 'lineChart',
        props: {
            lineData: [Array, Object]
        },
        data: function() {
            return {
                areaSeries: [],
                chart: ''
            }
        },
        mounted() {
            const graphElement = this.$refs.graph;
            this.chart = createChart(graphElement, {
                width: graphElement.getBoundingClientRect().width,
                height: graphElement.getBoundingClientRect().height,
                layout: {
                    backgroundColor: '#1E1D2C',
                    textColor: 'rgba(255, 255, 255, 0.9)'
                },
                grid: {
                    vertLines: {
                        color: '#2B2C3F',
                    },
                    horzLines: {
                        color: '#2B2C3F',
                    },
                },
                priceScale: {
                    borderColor: '#2B2C3F',
                    autoScale: true,
                    scaleMargins: {
                        top: 0.1,
                        bottom: 0,
                    },
                },
                timeScale: {
                    borderColor: '#2B2C3F',
                    barSpacing: 8,
                    lockVisibleTimeRangeOnResize: false,
                    rightBarStaysOnScroll: true,
                    borderVisible: false,
                    visible: true,
                    timeVisible: true,
                    secondsVisible: false,
                }
            })

            let i = 0;

            for (i = 0; i <= 10; i++) {
                this.areaSeries[i] = this.chart.addAreaSeries({
                    topColor: 'rgba(0, 117, 255, 0.1)',
                    bottomColor: 'rgba(0, 117, 255, 0.04)',
                    lineColor: '#0075FF',
                    lineWidth: 2, 
                });    
            }    
        },
        watch: {
            lineData: function() {
                const assetNames = Object.keys(this.lineData)
                const assetValues = Object.values(this.lineData)

                assetNames.forEach((asset, index) => {
                    const dates = Object.keys(assetValues[index]);
                    const chartData = Object.values(assetValues[index]);
                    let line = []

                    dates.forEach((date, index) => {
                        let newline = { time: date / 1000, value: chartData[index] };
                        line.push(newline);
                    })

                    this.areaSeries[index].applyOptions({
                        title: asset
                    })

                    this.areaSeries[index].setData(line)

                    this.chart.timeScale().fitContent();
                })
            }
        }
    }
</script>