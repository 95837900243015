<template>
    <div class="chart">
        <div ref="graph" class="w-full h-96"></div>
    </div>
</template>

<script>
    import { createChart } from 'lightweight-charts'

    export default {
        name: 'candleChart',
        props: {
            candleData: Array
        },
        data: function() {
            return {
                chart: null,
                candleSeries: {}
            }
        },
        mounted() {
            const graphElement = this.$refs.graph;
            this.chart = createChart(graphElement, {
                width: graphElement.getBoundingClientRect().width,
                height: graphElement.getBoundingClientRect().height,
                layout: {
                    backgroundColor: '#1E1D2C',
                    textColor: 'rgba(255, 255, 255, 0.9)'
                },
                grid: {
                    vertLines: {
                        color: '#2B2C3F',
                    },
                    horzLines: {
                        color: '#2B2C3F',
                    },
                },
                priceScale: {
                    borderColor: '#2B2C3F',
                    autoScale: true,
                    scaleMargins: {
                        top: 0.1,
                        bottom: 0.1,
                    },
                },
                timeScale: {
                    borderColor: '#2B2C3F',
                    barSpacing: 8,
                    lockVisibleTimeRangeOnResize: false,
                    rightBarStaysOnScroll: true,
                    borderVisible: false,
                    visible: true,
                    timeVisible: true,
                    secondsVisible: false,
                }
            })

            this.candleSeries = this.chart.addCandlestickSeries({
                upColor: '#0075FF',
                downColor: '#EF4444',
                borderDownColor: '#EF4444',
                borderUpColor: '#0075FF',
                wickDownColor: '#EF4444',
                wickUpColor: '#0075FF',
            })
        },
        watch: {
            candleData: function() {
                var minimumPrice = this.candleData[0].low
                var maximumPrice = 0
                
                for(var i = 1; i < this.candleData.length; i++) {
                    var priceLow = this.candleData[i].low
                    var priceHigh = this.candleData[i].high

                    if (priceHigh > maximumPrice) {
                        maximumPrice = priceHigh
                    }
                    if (priceLow < minimumPrice) {
                        minimumPrice = priceLow
                    }
                }

                const lineWidth = 2
                const minPriceLine = {
                    price: minimumPrice,
                    color: '#EF7739',
                    lineWidth: lineWidth,
                    axisLabelVisible: true,
                    title: 'bottom',
                };

                const maxPriceLine = {
                    price: maximumPrice,
                    color: '#EF7739',
                    lineWidth: lineWidth,
                    axisLabelVisible: true,
                    title: 'top',
                }

                this.candleSeries.createPriceLine(minPriceLine)
                this.candleSeries.createPriceLine(maxPriceLine)

                this.candleSeries.setData(this.candleData)

                this.chart.timeScale().fitContent()
            }            
        }
    }
</script>