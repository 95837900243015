<template>
    <div>
        <loader></loader>

        <div class="mb-4">
            <div class="flex items-center space-x-8 mb-2">
                <div class="flex items-center">
                    <img :src="asset.logo" :alt="asset.name" v-if="asset.logo" class="w-12 h-12 mr-4 rounded">
                    <h1 class="text-white text-2xl font-medium">{{ asset.fullname }} ({{ asset.name }})</h1>
                </div>

                <addToAssets v-bind:asset="asset"></addToAssets>
            </div>
            <div class="flex items-center space-x-2 text-white text-sm">
                <span class="uppercase">{{ asset.fullname }}</span>
                <span class="h-1.5 w-1.5 bg-azure-radiance block rounded-sm"></span>
                <span class="uppercase">{{ asset.exchange }}</span>
            </div>
        </div>

        <div class="flex space-x-4 mb-10">
            <div class="w-3/4 pr-4">
                <candleChart v-bind:candleData="candleData | chartFriendly "></candleChart>
            </div>
            <div class="flex flex-col space-y-4 w-1/4 p-4 text-white bg-charade" v-if="asset">
                <span class="text-xl">{{ asset.fullname }} ({{ asset.name }})</span>
                <div class="flex flex-col space-y-2">
                    <div class="flex flex-wrap">
                        <span class="text-spun-pearl">Industry</span>
                        <span class="ml-auto">{{ asset.industry }}</span>
                    </div>
                    <div class="flex flex-wrap">
                        <span class="text-spun-pearl">Sector</span>
                        <span class="ml-auto">{{ asset.sector }}</span>
                    </div>
                    <div class="flex flex-wrap">
                        <span class="text-spun-pearl">Exchange</span>
                        <span class="ml-auto">{{ asset.exchange }}</span>
                    </div>  
                    <div class="flex flex-wrap">
                        <span class="text-spun-pearl">Country</span>
                        <span class="ml-auto">{{ asset.country }}</span>
                    </div>   
                    <div class="flex flex-wrap">
                        <span class="text-spun-pearl">Currency</span>
                        <span class="ml-auto">{{ asset.currency }}</span>
                    </div>
                    <div class="flex flex-wrap">
                        <span class="text-spun-pearl">Website</span>
                        <a href="#" class="ml-auto text-azure-radiance underline focus-visible:outline-white">http://www.stonks.com</a>
                    </div>     
                </div>         
            </div>
        </div>
        <div v-if="asset" class="w-3/4 pr-4 mb-10 text-white">
            <h3 class="text-spun-pearl mb-2">Description</h3>
            {{ asset.description }}
        </div>
        <div class="w-2/4">
            <h2 class="mb-4 text-xl font-bold text-white">News about [[ Asset ]]</h2>
            <article class="flex flex-col bg-charade p-4">
                <h3 class="text-white font-bold order-2"><a href="">Are we in a bubble? How founder of world’s largest hedge fund says 2021 stock market stacks up</a></h3>
                <div class="mb-2 flex space-x-2 items-center text-white">
                    <a href="#" class="text-white uppercase border-b border-azure-radiance">NIO</a>
                    <span class="block self-stretch my-1 w-px bg-border-color"></span>
                    <div class="flex items-center space-x-2 text-xs">
                        <time>09:23</time>
                        <span class="h-1.5 w-1.5 bg-azure-radiance block rounded-sm"></span>
                        <time>Mon, 23 February 2021</time>
                    </div>
                </div>
            </article>
        </div>
    </div>
</template>


<script>
    import axios from "axios"
    import candleChart from '@/components/candlechart.vue'
    import addToAssets from '@/components/addToAssets.vue'
    import loader from '@/components/loader.vue'

    export default {
        name: 'Assets',
        components: {
            candleChart,
            addToAssets,
            loader
        },
        data() {
            return {
                title: 'Asset',
                asset: {},
                candleData: []
            }
        },
        filters: {
            chartFriendly: function(value) {
                const friendlyArray = value.map(line => {
                    const dateObject = new Date(line.time * 1000);
                    const formatedDate = Date.parse(dateObject.toISOString()) / 1000;
                    var newLine = {};

                    newLine = { time: formatedDate, open: line.open, high: line.high, low: line.low, close: line.close, value: line.volume };

                    return newLine
                })

                return friendlyArray
            }
        },
        mounted() {
            axios.get("https://ex.evr.ax:8001/data/timeseries/price?name="+ this.$route.params.AssetId +"&period=1000d")
                .then(response => {
                    const objects = Object.keys(response.data);
                    const objectData = Object.values(response.data);

                    objects.forEach((object, index) => {
                        if (object == 'asset') {
                            this.asset = objectData[index]
                        }

                        if (object == 'data') {
                            this.candleData = objectData[index]
                        }
                    })
                })
                .catch(err => {
                    console.log(err);

                    this.$root.$emit('showErrorMessage', 'Error', err)
                })                
        }
    }
</script>