<template>
    <div class="flex flex-grow w-full md:max-w-5xl mt-6 md:mt-0 md:ml-12">
        <div class="relative flex-grow mr-2">
            <svg class="absolute top-1/2 left-4 transform -translate-y-1/2" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.77 13.1659L11.1264 10.5301C11.9794 9.44347 12.4422 8.10162 12.4404 6.72022C12.4404 5.48998 12.0756 4.28736 11.3921 3.26445C10.7087 2.24154 9.73719 1.44428 8.60059 0.973489C7.464 0.502695 6.21332 0.379514 5.00672 0.619523C3.80011 0.859531 2.69178 1.45195 1.82186 2.32186C0.951949 3.19178 0.359531 4.30011 0.119523 5.50672C-0.120486 6.71332 0.0026951 7.964 0.473489 9.10059C0.944282 10.2372 1.74154 11.2087 2.76445 11.8921C3.78736 12.5756 4.98998 12.9404 6.22022 12.9404C7.60162 12.9422 8.94347 12.4794 10.0301 11.6264L12.6659 14.27C12.7382 14.3429 12.8242 14.4007 12.9189 14.4402C13.0137 14.4797 13.1153 14.5 13.218 14.5C13.3206 14.5 13.4222 14.4797 13.517 14.4402C13.6117 14.4007 13.6977 14.3429 13.77 14.27C13.8429 14.1977 13.9007 14.1117 13.9402 14.017C13.9797 13.9222 14 13.8206 14 13.718C14 13.6153 13.9797 13.5137 13.9402 13.4189C13.9007 13.3242 13.8429 13.2382 13.77 13.1659ZM1.55506 6.72022C1.55506 5.79754 1.82866 4.89558 2.34128 4.12839C2.85389 3.36121 3.58249 2.76327 4.43494 2.41017C5.28739 2.05708 6.2254 1.96469 7.13035 2.1447C8.0353 2.3247 8.86655 2.76902 9.51899 3.42145C10.1714 4.07389 10.6157 4.90514 10.7957 5.81009C10.9757 6.71504 10.8834 7.65305 10.5303 8.5055C10.1772 9.35795 9.57923 10.0865 8.81205 10.5992C8.04486 11.1118 7.1429 11.3854 6.22022 11.3854C4.98294 11.3854 3.79634 10.8939 2.92145 10.019C2.04656 9.1441 1.55506 7.9575 1.55506 6.72022Z" fill="#F4EFFE"/>
            </svg>

            <input type="text" placeholder="Search for tickers" v-model="ticker" @input="autoComplete()" @keydown="keyboardEvent($event)" class="p-3 pl-12 w-full h-full rounded bg-charade focus-visible:outline-white text-white border border-border-color">

            <div class="absolute w-full max-h-80 overflow-y-auto z-20 text-white bg-charade">
                <ul>
                    <li v-for="(ticker, index) in tickerArray" class="flex p-3 cursor-pointer hover:bg-border-color" v-bind:class="{'bg-border-color': isActive(index)}" ref="suggestions" v-bind="{'isactive': isActive(index)}" @click="goToDetail(ticker.name)">
                        <span class="w-4/12 md:w-2/12">{{ ticker.name }}</span>
                        <span class="flex-auto ml-4 text-premium-platinum">{{ ticker.fullname }}</span>
                    </li>
                </ul>
            </div>

        </div>
        <button type="submit" class="flex items-center text-white py-4 px-8 bg-azure-radiance rounded focus-visible:outline-white">
            <svg class="lg:hidden" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.77 13.1659L11.1264 10.5301C11.9794 9.44347 12.4422 8.10162 12.4404 6.72022C12.4404 5.48998 12.0756 4.28736 11.3921 3.26445C10.7087 2.24154 9.73719 1.44428 8.60059 0.973489C7.464 0.502695 6.21332 0.379514 5.00672 0.619523C3.80011 0.859531 2.69178 1.45195 1.82186 2.32186C0.951949 3.19178 0.359531 4.30011 0.119523 5.50672C-0.120486 6.71332 0.0026951 7.964 0.473489 9.10059C0.944282 10.2372 1.74154 11.2087 2.76445 11.8921C3.78736 12.5756 4.98998 12.9404 6.22022 12.9404C7.60162 12.9422 8.94347 12.4794 10.0301 11.6264L12.6659 14.27C12.7382 14.3429 12.8242 14.4007 12.9189 14.4402C13.0137 14.4797 13.1153 14.5 13.218 14.5C13.3206 14.5 13.4222 14.4797 13.517 14.4402C13.6117 14.4007 13.6977 14.3429 13.77 14.27C13.8429 14.1977 13.9007 14.1117 13.9402 14.017C13.9797 13.9222 14 13.8206 14 13.718C14 13.6153 13.9797 13.5137 13.9402 13.4189C13.9007 13.3242 13.8429 13.2382 13.77 13.1659ZM1.55506 6.72022C1.55506 5.79754 1.82866 4.89558 2.34128 4.12839C2.85389 3.36121 3.58249 2.76327 4.43494 2.41017C5.28739 2.05708 6.2254 1.96469 7.13035 2.1447C8.0353 2.3247 8.86655 2.76902 9.51899 3.42145C10.1714 4.07389 10.6157 4.90514 10.7957 5.81009C10.9757 6.71504 10.8834 7.65305 10.5303 8.5055C10.1772 9.35795 9.57923 10.0865 8.81205 10.5992C8.04486 11.1118 7.1429 11.3854 6.22022 11.3854C4.98294 11.3854 3.79634 10.8939 2.92145 10.019C2.04656 9.1441 1.55506 7.9575 1.55506 6.72022Z" fill="#F4EFFE"/>
            </svg>
            <span class="hidden lg:flex">Search</span>
        </button>
    </div>
</template>

<script>
    import axios from "axios"
    import _ from "lodash"

    export default {
        data: function() {
            return {
                ticker: '',
                tickerArray: [],
                current: 0
            }
        },
        updated: function() {
            this.$nextTick(function() {

                if (this.$refs.suggestions) {
                    this.$refs.suggestions.forEach(element => {
                        if (element.getAttribute('isactive') == 'true') {
                            element.scrollIntoView({block: 'center'})
                        }
                    })
                }
            })
        },
        methods: {
            fetchTickers: function() {
                if (this.ticker) {
                    axios.get("https://ex.evr.ax:8001/assets?name="+this.ticker)
                    .then(response => {
                        this.tickerArray = response.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            },
            goToDetail(AssetId) {
                // clear tickerArray and ticker after the user searched
                this.tickerArray = []
                this.ticker = ''

                // Go to /assets with the correct asset id
                this.$router.push({ name: 'Assets', params: {AssetId:AssetId}}).catch(err => {})
            },
            autoComplete: _.debounce(function() {
                this.current = 0
                this.fetchTickers()

                // clear ticker array when we have a empty search box
                if (this.ticker.length < 1) {
                    this.tickerArray = ''
                }
            }, 350),
            keyboardEvent: function(event) {

                if (event.key == 'ArrowDown') {
                    event.preventDefault()

                    if (this.current < this.tickerArray.length - 1) {
                        this.current++
                    }
                }

                if (event.key == 'ArrowUp') {
                    event.preventDefault()

                    if (this.current > 0) {
                        this.current--
                    }                
                }

                if (event.key == 'Enter') {
                    event.preventDefault()

                    this.goToDetail(this.tickerArray[this.current].name)
                }

                if (event.key == 'Escape') {
                    event.preventDefault()

                    this.tickerArray = ''
                }           
            },
            isActive: function(index) {
                return index === this.current
            }
        }
    }
</script>