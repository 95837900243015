import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import assets from '../views/assets.vue'
import momentum_tables from '../views/momentum_tables.vue'
import signals from '../views/signals.vue'
import news from '../views/news.vue'
import myassets from '../views/myassets.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/momentum_tables',
    name: 'Momentum Tables',
    component: momentum_tables
  },
  {
    path: '/signals',
    name: 'Signals',
    component: signals
  },
  {
    path: '/news',
    name: 'News',
    component: news
  },
  {
    path: '/assets/:AssetId',
    name: 'Assets',
    component: assets
  },
  {
    path: '/myassets',
    name: 'My Assets',
    component: myassets
  }
]

const router = new VueRouter({
  routes
})

export default router
