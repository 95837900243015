<template>
    <tr class="border-t border-border-color">
        <td class="px-4 py-1">
            <a v-bind:href="'#/assets/' + asset.name" class="border-b border-azure-radiance">{{ asset.name }}</a>
        </td>
        <td class="px-4 py-1">
            {{ asset.type }}
        </td>
        <td class="px-4 py-1">
            <input type="number" v-model="asset.nrShares" class="px-3 py-1 w-24 h-full rounded bg-charade text-white border border-border-color">
        </td>
        <td class="px-4 py-1">
            <input type="number" v-model="asset.historicPrice" class="px-3 py-1 w-32 h-full rounded bg-charade text-white border border-border-color"> {{ asset.currency }}
        </td>
        <td class="px-4 py-1 text-right">
            {{ asset.currentPrice | round }} {{ asset.currency }}
        </td>
        <td class="px-4 py-1 text-right">
            {{ asset.marketValue | round }} {{ asset.currency }}
        </td>
        <td class="px-4 py-1 text-right" v-bind:class="{'text-red-500': asset.percentageProfit < 1, 'text-green-500': asset.percentageProfit > 1}">
            {{ asset.percentageProfit | round }} %
        </td>
        <td class="px-4 py-1 text-right" v-bind:class="{'text-red-500': asset.totalProfit < 1, 'text-green-500': asset.totalProfit > 1}">
            {{ asset.totalProfit | round }}  {{ asset.currency }}
        </td>
    </tr>
</template>

<script>
    import axios from "axios"

    export default {
        name: 'myAsset',
        props: {
            asset: Object
        },
        watch: { 
      	    asset: {
                handler: function (val, oldVal) {
                    this.getUpdatedPrice(val);
                },
                deep: true
            }
        },
        mounted() {
            this.getUpdatedPrice(this.asset)
        },
        filters: {
            round: function (value) {
                if (!value) return ''
                value = value.toFixed(2)
                return value
            }
        },
        methods: {
            getUpdatedPrice: function(asset) {
                axios.get("https://ex.evr.ax:8001/data/timeseries/price?name="+ asset.name +"&period=1m")
                .then(response => {
                    const objects = Object.keys(response.data);
                    const objectData = Object.values(response.data);

                    objects.forEach((object, index) => {
                        if (object == 'asset') {
                            this.asset.currency = objectData[index].currency
                        }

                        if (object == 'data') {
                            this.asset.currentPrice = objectData[index][0].close
                            this.asset.marketValue = this.asset.nrShares * this.asset.currentPrice
                            this.asset.percentageProfit = ((this.asset.currentPrice / this.asset.historicPrice) - 1) * 100
                            this.asset.totalProfit = (this.asset.marketValue - (this.asset.nrShares * this.asset.historicPrice))
                        }
                    })
                })
                .catch(err => {
                    console.log(err);

                    this.$root.$emit('showErrorMessage', 'Error', err)
                })    
            }
        }
    }
</script>