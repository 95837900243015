<template>
    <button class="flex items-center py-1 px-4 color-azure-radiance border border-azure-radiance rounded text-white focus:outline-white" @click="addToAssets()">
        <svg :fill="fillAttr()" width="16" height="16" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus-circle" class="mr-2 text-azure-radiance" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M384 250v12c0 6.6-5.4 12-12 12h-98v98c0 6.6-5.4 12-12 12h-12c-6.6 0-12-5.4-12-12v-98h-98c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h98v-98c0-6.6 5.4-12 12-12h12c6.6 0 12 5.4 12 12v98h98c6.6 0 12 5.4 12 12zm120 6c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z"></path></svg>
        <span v-if="!isAdded()">Add to assets</span>
        <span v-if="isAdded()">Already added to assets</span>
    </button>
</template>

<script>
    export default {
        name: 'addToAssets',
        props: {
            asset: Object
        },
        data: function() {
            return {
                myAssets: []
            }
        },
        mounted() {
            if (localStorage.getItem('myAssets')) {
                try {
                    this.myAssets = JSON.parse(localStorage.getItem('myAssets'))
                } catch(e) {
                    localStorage.removeItem('myAssets')
                }
            }
        },
        methods: {
            addToAssets: function() {
                // Todo: search in object
                if (!this.myAssets.includes(this.asset.name)) {
                    console.log(this.asset.asset_type)

                    this.myAssets.push({
                        name: this.asset.name,
                        type: this.asset.asset_type,
                        nrShares: 1,
                        avgCost: 0
                    })
                    localStorage.setItem('myAssets', JSON.stringify(this.myAssets))
                }
            },
            isAdded: function() {
                // Todo: search in object
                return this.myAssets.includes(this.assetName)
            },
            fillAttr: function() {
                return (this.isAdded() ? '#0075FF' : 'none')
            }
        }
    }
</script>