<template>
    <transition
        enter-active-class="transition-all duration-200 ease-out-squad"
        leave-active-class="transition-all duration-100 ease-in-squad"
        enter-class="opacity-0 scale-50"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-50"
    >
    <div class="fixed top-8 right-8 z-10 bg-white rounded shadow-xl transform" v-if="showMe">
            <div class="flex p-4 items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-red-100">
                    <svg class="h-6 w-6 text-red-600" x-description="Heroicon name: outline/exclamation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                </div>
                <div class="ml-4">
                    <h3 class="text-lg">{{ errorSubject }}</h3>
                    <div class="mt-1">
                        <p class="text-gray-500">
                            {{ errorText }}
                        </p>
                    </div>
                </div>
                <div class="ml-4">
                    <button @click="showMe = !showMe">
                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>      
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'error-message',
        data: function () {
            return {
                errorSubject: '',
                errorText: '',
                showMe: false
            }
        },
        mounted() {
            this.$root.$on('showErrorMessage', (errorSubject, errorText) => {
                this.errorSubject = errorSubject
                this.errorText = errorText
                this.showMe = true

                const vm = this

                setTimeout(function() { 
                    vm.errorSubject = ''
                    vm.errorText = ''
                    vm.showMe = false
                }, 5000);
            })
        }
    }
</script>