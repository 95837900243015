<template>
    <div>
        <div class="flex items-center justify-between mb-6">
            <h1 class="text-white text-2xl font-medium">My assets</h1>
        </div>

        <table class="table-fixed text-white w-full">
            <thead class="bg-woodsmoke border-t border-border-color">
                <th class="text-left px-4 py-4">Asset</th>
                <th class="text-left px-4 py-4">Type</th>
                <th class="text-left px-4 py-4">Shares</th>
                <th class="w-64 text-left px-4 py-4">Avg. cost/share</th>
                <th class="text-left px-4 py-4 text-right">Price</th>
                <th class="text-left px-4 py-4 text-right">Market value</th>
                <th class="text-left px-4 py-4 text-right">% profit</th>
                <th class="text-left px-4 py-4 text-right">Total profit</th>
            </thead>
            <tbody>
                <myAsset :asset="asset" v-for="(asset, index) in sortedAssets" :key="index" :class="{'bg-woodsmoke': index % 2}"></myAsset> 
                <tr class="bg-woodsmoke border-t border-border-color">
                    <th class="text-left px-4 py-4 align-top">Total</th>
                    <th class="text-left px-4 py-4"></th>
                    <th class="text-left px-4 py-4"></th>
                    <th class="w-64 text-left px-4 py-4"></th>
                    <th class="text-left px-4 py-4"></th>
                    <th class="text-left px-4 py-4 text-right">{{ totalMarketValue | round }} EUR </th>
                    <th class="text-left px-4 py-4 align-top text-right">{{ totalAvgProfit | round }} %</th>
                    <th class="text-left px-4 py-4 text-right">{{ totalProfit | round }} EUR </th>
                </tr>           
            </tbody>
        </table>
    </div>
</template>

<script>
    import axios from "axios";
    import myAsset from '@/components/myAsset.vue'

    var STORAGE_KEY = "myAssets"
    var assetStorage = {
        fetch: function() {
            var assets = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]")
           
            assets.forEach(asset => {
                asset.name = asset.name
                asset.type = asset.type
                asset.nrShares = asset.nrShares
                asset.historicPrice = asset.historicPrice
                asset.currentPrice = asset.currentPrice
                asset.marketValue = asset.marketValue
                asset.percentageProfit = asset.percentageProfit
                asset.totalProfit = asset.totalProfit
                asset.currency = asset.currency
            })

            assetStorage.uid = assets.length
            return assets
        },
        save: function(assets) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(assets))
        }
    };

    export default {
        name: 'myAssets',
        components: {
			myAsset
		},
        data: function() {
            return {
                storedAssets: assetStorage.fetch(),
                totalMarketValue: 0,
                totalAvgProfit: 0,
                totalProfit: 0,
                currentExchangeRate: 0
            }
        },
        mounted() {
            // get current exchange rate for USD -> EUR
            axios.get("https://freecurrencyapi.net/api/v2/latest?apikey=c0ef2f10-4c9f-11ec-abed-3f89a0ba1d63&base_currency=USD")
            .then(response => {
                this.currentExchangeRate = response.data.data.EUR
            })
            .catch(err => {
                console.log(err)

                this.$root.$emit('showErrorMessage', 'Error', err)
            })
        },
        computed: {
            sortedAssets: function() {
                return this.storedAssets
                // return this.storedAssets.sort((a,b) => {
                //     return b.marketValue - a.marketValue;
                // })
            }
        },
        watch: {
            storedAssets: {
                handler: function(assets) {
                    console.log('watch handler');

                    // calculate totals
                    this.calculateTotal(assets)

                    // remove assets with 0 number of shares
                    assets.forEach(asset => {
                        if (asset.nrShares == 0) {
                            this.removeAsset(asset)
                        }
                    })
                    
                    // save in localstorage
                    assetStorage.save(assets)
                },
                deep: true
            }
        },
        methods: {
            calculateTotal: function(assets) {
                // Always a fresh calculation
                this.totalMarketValue = 0
                this.totalProfit = 0
                this.totalAvgProfit = 0

                assets.forEach(asset => {
                    if (asset.currency == 'USD' || asset.currency == 'USDT') {
                        this.totalMarketValue += this.formatUSDtoEUR(asset.marketValue)
                        this.totalProfit += this.formatUSDtoEUR(asset.totalProfit)
                    } else if (asset.currency == 'EUR') {
                        this.totalMarketValue += asset.marketValue
                        this.totalProfit += asset.totalProfit
                    }

                    this.totalAvgProfit += asset.percentageProfit / assets.length
                })
            },

            removeAsset: function(asset) {
                this.storedAssets.splice(this.storedAssets.indexOf(asset), 1)
            },

            formatUSDtoEUR: function(value) {
                return value * this.currentExchangeRate
            }
        },
        filters: {
            round: function (value) {
                if (!value) return ''
                value = value.toFixed(2)
                return value
            }
        }
    }
</script>